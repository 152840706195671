.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none !important;

}

.ring{
  background-color: #dfe2e7;
}

.dhubuliabutton{
  background-color: #9e713b; /* Green */
  border: none;
  color: rgb(246, 248, 248);
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
}


.buttonshare{
  background-color: #4b4948; /* Green */
  border: none;
  color: rgb(246, 248, 248);
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 14px;
}

.container {
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 200;
  font-style: normal;
}
.topjewellersdhubulia{
  background-color: #dfe2e7;
}

.GOLDRATE{
background-color: #ebf1f3;
}

.radiusimage img{
  border-radius: 14px;
}

.buttongold{
  background-color: #7d5104; /* Green */
  border: none;
  color: rgb(246, 248, 248);
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 03px;
}