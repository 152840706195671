.backm{
    background-color: aliceblue;
}

.radius img{
    border-radius: 14px;
}

.bhubuliajewellers{
    background-color: #f1dfdc;
    padding: 4px;
}